import pluralize from 'pluralize';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DndProvider,
  type DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { type Repository, useArrayState } from '../../../hooks/useArrayState';
import { useAsyncCall, useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import { type Tag } from '../../../types';
import { GameLikeLabelMap, type GamePack } from '../../../types/game';
import { err2s } from '../../../utils/common';
import { canMove } from '../../../utils/dnd';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { MenuIcon } from '../../icons/MenuIcon';
import { Loading } from '../../Loading';
import { GamePackSelect } from './GamePackSelect';

const MAX_PINNED_PER_TAG = 1000;

type Props = {
  tag: Tag;
  onComplete: () => void;
  onCancel: () => void;
};

type DragGamePack = {
  id: number;
  index: number;
};
type MoveHandler = (from: number, to: number) => void;
type DeleteHandler = (pack: GamePack) => void;

function PinnedGamePack(props: {
  pack: GamePack;
  index: number;
  onMove: MoveHandler;
  onDelete: DeleteHandler;
}): JSX.Element {
  const { pack, index, onMove, onDelete } = props;
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'pinned-game-pack',
    hover(item: DragGamePack, monitor: DropTargetMonitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      if (canMove(dragIndex, hoverIndex, hoverBoundingRect, monitor)) {
        onMove(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });
  const [collected, drag, drapPreview] = useDrag({
    type: 'pinned-game-pack',
    item: () => {
      return { id: pack.id, index };
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 'opacity-40' : '',
    }),
  });

  drapPreview(drop(ref));

  const handleDelete = () => {
    onDelete(pack);
  };

  const gamesCount = pack.childrenIds?.length ?? 0;

  return (
    <div className={`flex items-center ${collected.opacity}`} ref={ref}>
      <button type='button' ref={drag} className='btn cursor-move'>
        <MenuIcon />
      </button>
      <div className='flex items-center justify-between flex-grow mx-2 my-1 px-3 border border-secondary rounded-xl h-10 select-none'>
        <div className='text-sms'>{pack.name}</div>
        <div className='text-sms text-secondary'>
          {`${gamesCount} ${pluralize(GameLikeLabelMap['game'], gamesCount)}`}
        </div>
      </div>
      <button
        type='button'
        className='btn flex w-10 h-10 border border-secondary rounded-xl items-center justify-center text-red-002'
        onClick={handleDelete}
      >
        <DeleteIcon />
      </button>
    </div>
  );
}

function PinnedGamePacks(props: {
  tag: Tag;
  packs: GamePack[];
  setPacks: (packs: GamePack[]) => void;
  packDao: Repository<GamePack>;
}): JSX.Element {
  const { tag, packs, setPacks, packDao } = props;

  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback(async () => {
      const resp = await apiService.gamePack.getFeaturedGamePacksByTag(tag.id);
      setPacks(resp.data.gamePacks);
    }, [tag.id, setPacks])
  );

  useEffect(() => {
    call();
  }, [call]);

  const handleMove = (from: number, to: number): void => {
    const updated = [...packs];
    const pack = updated[from];
    updated.splice(from, 1);
    updated.splice(to, 0, pack);
    setPacks(updated);
  };

  if (state.isRunning) return <Loading text='' />;
  if (error)
    return (
      <div className='text-red-002 text-sms text-center'>{err2s(error)}</div>
    );

  if (packs.length === 0)
    return (
      <div className='text-secondary text-sms text-center'>
        No game packs found
      </div>
    );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='flex flex-col max-h-120 pr-1 overflow-y-auto scrollbar'>
        {packs.map((pack, i) => (
          <PinnedGamePack
            key={pack.id}
            pack={pack}
            index={i}
            onMove={handleMove}
            onDelete={packDao.deleteItem}
          />
        ))}
      </div>
    </DndProvider>
  );
}

function PinAll(props: { tagId: number; dao: Repository<GamePack> }) {
  const { dao, tagId } = props;

  const {
    call,
    state: { state },
  } = useLiveAsyncCall(async () => {
    const paginator = apiService.gamePack.getGamePacksByTagId(
      tagId,
      false,
      MAX_PINNED_PER_TAG
    );
    return await paginator.next();
  });

  const handlePinAll = async () => {
    const packs = await call();
    if (!packs) return;
    for (const pack of packs) {
      if (dao.hasItem(pack)) continue;
      dao.addItem(pack);
    }
  };

  return (
    <button
      type='button'
      className='btn text-primary text-sms underline flex items-center justify-center gap-1'
      onClick={handlePinAll}
      disabled={state.isRunning}
    >
      {state.isRunning && <Loading text='' imgClassName='w-5 h-5' />}
      Pin all game packs in this list
    </button>
  );
}

function ManagePinnedGamePacksModal(props: Props): JSX.Element {
  const { tag } = props;
  const [packs, setPacks, packDao] = useArrayState<GamePack>({
    compare: (a, b) => a.id === b.id,
  });
  const [checkError, setCheckError] = useState<string | null>(null);

  const {
    state: { transformed: state },
    error: saveError,
    call: save,
  } = useAsyncCall(
    useCallback(async (tagId: number, packIds: string[]) => {
      return apiService.gamePack.updateFeaturedGamePacksByTag(tagId, packIds);
    }, [])
  );

  const handlePickGamePack = (pack: GamePack | null) => {
    if (!pack) return;
    packDao.prependItem(pack);
  };

  const handleSave = async () => {
    const packIds = packs.map((p) => p.id);
    if (packIds.length > MAX_PINNED_PER_TAG) {
      setCheckError(`You can only pin up to ${MAX_PINNED_PER_TAG} game packs`);
      return;
    } else {
      setCheckError(null);
    }
    const resp = await save(tag.id, packIds);
    if (!resp) return;
    props.onComplete();
  };

  const error = saveError ?? checkError;

  return (
    <ModalWrapper containerClassName='w-160 ' borderStyle='white'>
      <div className='w-full h-full min-h-52 flex flex-col items-center justify-center px-12 py-10'>
        <header className='font-medium text-2xl flex flex-col items-center'>
          <div className='text-center'>
            Reorder Pinned Game Packs for <div>{tag.name}</div>
          </div>
        </header>
        <section className='w-full my-5'>
          <div className='flex items-center justify-between'>
            <div className='text-base font-medium my-2'>Pin a Game Pack</div>
            <PinAll tagId={tag.id} dao={packDao} />
          </div>
          <GamePackSelect
            onChange={handlePickGamePack}
            filter={(pack) => !packs.find((p) => p.id === pack?.id)}
            tagId={tag.id}
            placeholder='Pick a game pack'
          />
          <div className='text-base font-medium my-2'>Pinned Game Packs</div>
          <PinnedGamePacks
            tag={tag}
            packs={packs}
            setPacks={setPacks}
            packDao={packDao}
          />
          {error && <div className='text-red-002 text-sms'>{err2s(error)}</div>}
        </section>
        <footer className='w-full flex items-center justify-center gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 h-10 flex items-center justify-center'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 h-10 flex items-center justify-center'
            disabled={state.isRunning}
            onClick={handleSave}
          >
            {state.isRunning && <Loading text='' />}
            <div className='ml-2'>Save</div>
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

export function useTriggerManagePinnedGamePacksModal(): (
  props: Omit<Props, 'onCancel'>
) => void {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  return useCallback(
    (props: Omit<Props, 'onCancel'>) => {
      triggerFullScreenModal({
        kind: 'custom',
        containerClassName: 'bg-black bg-opacity-60',
        element: (p) => (
          <ManagePinnedGamePacksModal
            tag={props.tag}
            onComplete={() => {
              p.internalOnConfirm();
              props.onComplete();
            }}
            onCancel={p.internalOnConfirm}
          />
        ),
      });
    },
    [triggerFullScreenModal]
  );
}
